@use "@functions/theme.scss";
@use "@variables/colors.scss";
@use "@variables/typography.scss";

.root {
  align-items: flex-start;
  display: flex;
  padding: 0;
}

.input {
  color: colors.$primary;
  font-weight: typography.$font-weight-medium;
  margin-top: 1px;

  &__input {
    padding-top: 3px;
    line-height: 14px;
    height: 14px;

    &:focus {
      &::placeholder {
        opacity: 0.6;
      }
    }

    &::placeholder {
      color: colors.$grey;
      opacity: 1;
    }
  }
}

.dateControl {
  width: auto;
}

.timeControl {
  width: theme.spacing(12);
}

.progress {
  margin: theme.spacing(1, 3, 0, 0);
}

.focusProgressLabel {
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
}

.focusProgressSubLabel {
  font-size: 10px;
  letter-spacing: 0;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
}

.flexFields {
  display: flex;
  margin-left: theme.spacing(4);
  margin-bottom: theme.spacing(1.5);

  &__label {
    white-space: nowrap;
    padding-bottom: 2px;
    color: colors.$black;
    padding-top: theme.spacing(0.75);
    width: 60px;
  }

  &__autoLabel {
    width: auto;
    padding-top: theme.spacing(0.75);
    margin-left: theme.spacing(1);
    margin-right: theme.spacing(1);
  }
}

.durationField {
  max-width: 100px;
}

.submitBtn {
  float: right;
}
