@use "@variables/zIndex.scss";
@use "@variables/colors.scss";
@use "@functions/theme.scss";

.popper {
  z-index: zIndex.$modal;
}

.paper {
  margin: theme.spacing(1, 0);
  min-width: 13em;
  border: 1px solid #f7f8fc;
  padding: theme.spacing(1, 2);

  :global(.MuiInputAdornment-positionEnd) {
    display: none;
  }

  :global(.MuiPickersCalendarHeader-transitionContainer) {
    height: 26px;
  }
}

.picker {
  background-color: colors.$white;

  &--micro {
    margin: 0;
    min-width: 220px;
    padding-top: theme.spacing(2);
    
    :global(.MuiPickersCalendarHeader-label) {
      font-size: 16px;
    }
  
    :global(.MuiDayCalendar-header .MuiDayCalendar-weekDayLabel) {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  
    :global(.MuiButtonBase-root.MuiPickersDay-root),
    :global(.MuiPickersDay-root.MuiPickersDay-hiddenDaySpacingFiller) {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  
    :global(.MuiDateCalendar-root) {
      :global(.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition) {
        min-height: 200px;
      }
    }
  }
}

.timePickerBox {
  margin: theme.spacing(0, 3, 3, 3);
}

.timeDivider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  height: 1em;
  padding: 0;
  border-width: 1;
  margin-bottom: theme.spacing(4);
}

.sectionTitle {
  text-align: center;
  margin: theme.spacing(0, 2);
  cursor: pointer;
}

.divider {
  flex: 1;
  height: 2px;
  background-color: colors.$divider;
}

.inlinePicker {
  background-color: transparent;
}
