/* Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Source+Code+Pro:wght@300;400;500&display=swap");
/* Source Code Pro */
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400&display=swap");

#__next {
  position: relative;
  z-index: 0;
}

/* Apercu Pro */
@font-face {
  font-family: "Apercu Pro";
  src:
    url("/fonts/apercu/apercu-regular-pro.woff2") format("woff2"),
    url("/fonts/apercu/apercu-regular-pro.woff") format("woff"),
    url("/fonts/apercu/apercu-regular-pro.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Apercu Pro";
  src:
    url("/fonts/apercu/apercu-medium-pro.woff2") format("woff2"),
    url("/fonts/apercu/apercu-medium-pro.woff") format("woff"),
    url("/fonts/apercu/apercu-medium-pro.ttf") format("truetype");
  font-style: medium;
  font-weight: 700;
  font-display: swap;
}

/* Color emoji */
@font-face {
  font-family: "color-emoji";
  src: local("Apple Color Emoji"), local("Segoe UI Emoji"), local("Segoe UI Symbol"), local("Noto Color Emoji");
  font-display: swap;
}

body {
  padding: 0;
  margin: 0;
  font-family:
    "Poppins",
    -apple-system,
    "Segoe UI",
    Helvetica,
    Arial,
    sans-serif,
    color-emoji;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fc;
}

code {
  font-family: "Source Code Pro", "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
  font-weight: 300;
}

#__next {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

kbd {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-family: "Poppins", "-apple-system", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif",
    '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"';
  margin: 0 0.2em 0 0.3em;
  padding: 0 0.4em;
}

/* This should hide the intercom logo box that pops up momentarily on load */
.intercom-lightweight-app {
  display: none;
}
