$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

// For JS Imports
$exports: (
  fontWeightBold: $font-weight-bold,
  fontWeightMedium: $font-weight-medium,
  fontWeightRegular: $font-weight-regular,
);
